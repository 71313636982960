// Styles for Views
// -----------------------------------------------------------------------------

.view-evt-products-by-category {
  .view-content {
    margin-left: -15px;
    margin-right: -15px;

    @include clearfix;
  }
}

.view-evt-categories {
  .taxonomy-list {
    margin-bottom: 2em;
  }

  ul {
    @include clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }

  li {
    margin: 0;
    float: left;
    display: block;
    margin-bottom: 10px;
    
    @media screen and (min-width: $screen-sm-min) {
      width: 25%;
    }
  }

  a {
    text-align: center;
    display: block;
    background: #fff;
    color: $vinum_color3;
    padding: 10px 0;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid $vinum_color3;

    &:hover {
      background: $vinum_color3;
      color: #fff;
    }
  }
}