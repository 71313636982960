
.footer {
  color: $footer_color;
  background: $footer_bg;
  padding-top: 30px;
  padding-bottom: 30px;
}

.subfooter {
  color: $subfooter_color;
  background: $subfooter_bg;
  padding-top: 10px;
  padding-bottom: 10px;

  p:last-child {
    margin-bottom: 0;
  }
}
