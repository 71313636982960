// Mixins
// -----------------------------------------------------------------------------
@mixin img-resp {
  display: block;
  width: 100%;
  height: auto;
}

@mixin whitebox {
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
    position: relative;
    background-color: #ffffff;
    padding: 50px;
    margin-bottom: 40px;
}