ul.language-switcher-locale-url {
  float: right;
  margin: 0;
  list-style: none;

  li {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
  }

  a {
    display: inline-block;
    color: $subfooter_color;
  }

  img {
    vertical-align: inherit;
  }
}
