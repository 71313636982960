// Styles for blocks.
// -----------------------------------------------------------------------------

.region-content-post .block {
  //margin-bottom: 40px;
}

.region-footer-1, .region-footer-2, .region-footer-3, .region-footer-4 {
  .block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .block__title {
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 1.5em;
    letter-spacing: 2px;
  }
}

.block--white-shadow {
  border-radius: 0;
  box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
  position: relative;
  background-color: #ffffff;

  .block__title {
    background: lighten($vinum_color5, 2%);
    padding: 10px 30px;
    margin: 0;
  }
}

.prime-color-image {
  margin-bottom: 2em;
  img {
    width: 100%;
  }
}

.pc-block {
  .content {
    margin-left: -15px;
    margin-right: -15px;
  }

  &__left {
    padding: 50px 50px 50px 65px;

    .btn {
      margin-top: 2.5em;
    }
  }
  &__left-image {
    margin-top: 3.5em;
  }
  &__right {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}