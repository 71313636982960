// Styles for Menus and Navs
// -----------------------------------------------------------------------------

.footer .nav {
  a {
    color: $subfooter_color;

    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}
