// Page Layout
// -----------------------------------------------------------------------------

//body {
//  &.html, &.admin-menu {
//    padding-top: 90px;
//  }
//}

.overlay-element {
  padding-top: 90px;
}

#header {
  margin-top: 40px;
}

#content {
  margin-top: 30px;
  margin-bottom: 30px;
}

#footer {
  margin-bottom: 0;
}

// Page Layout
// -----------------------------------------------------------------------------

.page-header {
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-bottom: 30px;
}

h1.title {
  text-align: center;
}

body.front .content-pre {
  box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
  position: relative;
  background-color: #ffffff;
  padding: 0px;
  margin-top: 4em;
  margin-bottom: 4em;
}

.content {
  &__main {
  }
  &__main--white {
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
    position: relative;
    background-color: #ffffff;
    padding: 50px;
    margin-top: 4em;
    margin-bottom: 4em;
  }
}

.content-post {
  margin-top: 4em;
  margin-bottom: 4em;
}

.region-content-post {
  @include clearfix();
}
