// Styles for panels and panes.
// -----------------------------------------------------------------------------

//.pane-taxonomy-term-field-description-head {
//  box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
//  position: relative;
//  background-color: #ffffff;
//  padding: 50px;
//  margin-bottom: 40px;
//}

.l-evt-zebra {
  &__pre {
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
    position: relative;
    background-color: #ffffff;
    padding: 50px;
    margin-bottom: 40px;

    h1 {
      margin-top: 2px;
    }
  }

  &__main {
  }

  &__post {
  }
}