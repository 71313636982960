.header-menu-block {
  overflow: hidden;
  border-radius: 0;
  box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
  position: relative;
  background-color: #ffffff;
}

.header-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    display: block;
    border-bottom: 1px solid #bfbfbf;

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    display: block;
    padding: 33px 30px;
    border-right: solid;
    border-right-width: 4px;
    border-right-color: #fff;


    &:hover {
      background-color: #f8f9fb;
      border-right-color: $vinum_color3;
      text-decoration: none;
    }
  }

  &__badge {
    display: inline-block;
    margin-bottom:10px;
    color: $vinum_color2;
    text-transform: uppercase;
    font-size: 15px;
  }

  &__text {
    display: block;
    font-weight:300;
    font-size: 1.6em;
    color: $vinum_color3;
  }
}