// text-indent: -9999px;

.block-payments {
  background-color: #ffffff;
  color: #3f3f3f;
  padding: 10px;
}

.block-payments h2 {
  display: none;
}

.block-payments ul.menu {
  height: 40px;
  margin-bottom: 0;
}

.block-payments ul.menu li {
  display: inline;
  float: left;
  /* LTR */
  margin: 0;
  text-align: center;
  width: 24%;
  padding: 0;
}

.block-payments ul.menu li:first-child {
  padding-left: 10px;
  /* LTR */
}

.block-payments ul.menu li a {
  background-position: 0 100%;
  background-repeat: no-repeat;
  display: block;
  height: 30px;
  margin: 5px 0px;
  text-indent: -9999px;
  width: 50px;
}

.block-payments ul.menu li .visa {
  background-image: url("../images/picto_visa_premier.png");
}

.block-payments ul.menu li .paypal {
  background-image: url("../images/picto_paypal.png");
}

.block-payments ul.menu li .mastercard {
  background-image: url("../images/picto_mastercard.png");
}

.block-payments ul.menu li .american-express {
  background-image: url("../images/picto_american_express.png");
}
