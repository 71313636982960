.js-overlay {
  transition: opacity 0.45s cubic-bezier(.3, 0, .15, 1), visibility 0.45s linear;
  tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  opacity: .01;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5000;
  visibility: hidden;

  &.is--open {
    transition-delay: 0;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }
}

div.alert-commerce-add-to-cart-confirmation {
  width: 30rem;
  transition: all .45s cubic-bezier(.3, 0, .15, 1);
  margin-right: 0;
  display: block;
  height: 100%;
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  padding: 0;
  background: #fff;
  z-index: 8000;

  .added-product-title {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
    padding: 15px;
    margin-bottom: 21px;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .button {
    > a, span {
      display: block;
      padding: 1em;
      background: $btn-primary-bg;
      color: $btn-primary-color;
      text-align: center;
      margin-left: 1em;
      margin-right: 1em;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    span {
      background: #aaaaaa;
      color: #282828;
      cursor: pointer;
    }
  }

  .view-content {
    padding: .625rem;
  }

  .field-label {
    font-weight: 100;
  }
}
