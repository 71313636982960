// Typography
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'monday';
  src: url('../fonts/monday.eot?sd882');
  src: url('../fonts/monday.eot?w7j9bd#iefix') format('embedded-opentype'), url('../fonts/monday.ttf?w7j9bd') format('truetype'), url('../fonts/monday.woff?w7j9bd') format('woff'), url('../fonts/monday.svg?w7j9bd#monday') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  font-weight: 100;
}

a, a:hover, a:visited {
  text-decoration: none;
}

a.link-icon:before {
  font-family: 'Monday';
  content: "\e600";
  font-size: 15px;
  line-height: 15px;
}
