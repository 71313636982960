.bean-karte {
  margin-top: 2em;
  margin-bottom: 2em;
  
  > .block__content {
    overflow: hidden;
    border-radius: 0;
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
    position: relative;
    background-color: $vinum_color3;
    color: #ffffff;
  }

  &__map, &__info {
    float: left;
  }

  &__map {
    width: 69%;
  }

  &__info {
    width: 31%;
    padding: 30px;
  }
}