.view-evt-recommend-products,
.view-evt-products-overview,
.view-evt-products-by-category {
  .evt-product {
    padding: 30px;
    background: #ffffff;
    color: #5c5c5c;
    overflow: hidden;
    background: #ffffff none 0 0 repeat;
    border-radius: 0;
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: $screen-sm-min) {
      height: 500px;
    }

    &__images {
      border-bottom-width: 4px;
      border-bottom-color: $vinum_color3;
      border-bottom-style: solid;
      margin: -30px -30px 30px -30px;
    }

    &__content {
      margin-bottom: 11px;
    }

    &__meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 30px;
      padding-bottom: 10px;
    }

    .field-name-title-field {
      font-weight: 300;
      margin-top: 0;
    }

    .field-name-field-product-category {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 0.8em;
      padding: 2px 4px;
    }
  }
}

.node-type-evt-cartridge-product .evt-product {
  h3 {
    background: $vinum_color2;
    color: #fff;
    padding: 5px 15px;
  }

  &__content {
    @include whitebox;
  }
}

.node-type-evt-cartridge-product .evt-product-head {
  @include whitebox;

  > div {
    @include clearfix;
  }

  &__images {
    @media screen and (min-width: $screen-sm-min) {
      width: 50%;
      float: left;
    }
  }

  &__shop {
    @media screen and (min-width: $screen-sm-min) {
      width: 50%;
      float: left;
    }
  }
}
