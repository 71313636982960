 //FILE NAME SUGGESTIONS:
 //  * bean--aussteller-teaser.tpl.php
 //  * bean--frontpage-teaser-block--default.tpl.php
 //  * bean--frontpage-teaser-block.tpl.php
 //  x bean.tpl.php
 //  * entity.tpl.php

 //FILE NAME SUGGESTIONS:
 // * block--bean--aussteller-teaser.tpl.php
 // * block--bean.tpl.php
 // * block--content-post.tpl.php
 // x block.tpl.php

 .bean-frontpage-block {

    padding: 30px;
    background: #ffffff;
    color: #5c5c5c;
    overflow: hidden;
    background: #ffffff none 0 0 repeat;
    border-radius: 0;
    box-shadow: 0 5px 8px 0 rgba(160, 166, 168, 0.35);

   .field-name-field-fb-image {
     border-bottom-width: 4px;
     border-bottom-color: $vinum_color3;
     border-bottom-style: solid;
     margin: -30px -30px 30px -30px;
   }

   .field-name-field-fb-subtext {
     text-transform: uppercase;
     color: $vinum_color3;
     letter-spacing:1px;
     margin-bottom: 10px;
     font-size: 1em;
   }
   
   .field-name-title-field {
     font-weight: 300;
     margin-top: 0;
   }

   .field-name-field-fb-description {
     min-height: 120px;
   }

   .field-name-field-teaser-block-link {
   }

 }