.navbar_old {
  &__menu, &__logo, &__project, &__blocks {
    float: left;
  }

  &__menu {
    width: 8%;

    @media screen and (min-width: $screen-lg-min) {
      width: 5%;
    }
  }

  &__logo {
    width: 50%;

    .navbar-brand {
      padding: 27px 10px 0;

      @media screen and (min-width: $screen-sm-min) {
        padding: 10px;
      }

    }

    @media screen and (min-width: $screen-lg-min) {
      width: 435px;
    }

    img {
      display: block;
      height: auto;
      width: 100%;

      @media screen and (min-width: $screen-md-min) {
        height: 70px;
        width: auto;
      }
    }
  }

  &__project {
    width: 30%;
    margin-left: 2em;

    .navbar-brand {
      padding: 25px 10px 0;
      @media screen and (min-width: $screen-sm-min) {
        padding: 10px;
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      width: 20%;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: 180px;
      margin-left: 17em;
    }

    img {
      display: block;
      height: auto;
      width: 100%;

      @media screen and (min-width: $screen-md-min) {
        display: block;
        height: 70px;
        width: auto;
      }
    }
  }

  &__blocks {
    display: none;

    @media screen and (min-width: $screen-sm-min) {
      display: block;
    }
  }

  &__menu {
    .menu-btn {
      font-size: 2.0em;
    }
  }

  &__cart {
    float: right;

    i {
      font-size: 1.4em;
    }
  }

}
